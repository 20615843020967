<template>
  <VAsync
    v-slot="promise"
    :await="versionsRequest"
    @reject="$notify({ type: 'error', text: $t('error') })"
  >
    <p v-if="promise.pending">
      {{ $t('loading') }}
    </p>

    <div v-else style="padding-left: 2rem; padding-right: 2rem;">
      <table>
        <template v-for="algorithm in promise.results">
          <tr :key="algorithm.id + '-1'">
            <td>v{{ algorithm.fileVersion }}</td>
            <td style="padding-left:6rem;">
              {{ algorithm.created | date('ll') }}
            </td>
            <td style="padding-left:6rem;">
              <IdxBtn
                slot="trigger"
                :link="true"
                :small="true"
                :aria-controls="`toggle-${algorithm.id}`"
                @click="toggleShownJson(algorithm.id)"
              >
                {{
                  showJson(algorithm.id) ? $t('button-hide') : $t('button-show')
                }}
              </IdxBtn>
              |
              <IdxBtn
                :link="true"
                :small="true"
                @click="download(algorithm.id)"
              >
                {{ $t('download') }}
              </IdxBtn>
              |
              <IdxBtn
                :small="true"
                :link="true"
                @click="copyJson(algorithm.id)"
              >
                {{ $t('button-copy') }}
              </IdxBtn>
              |
              <a :href="getPipelineGuiUrl(algorithm.id)" target="_blank">
                {{ $t('open-gui') }}
              </a>
              |
              <IdxBtn
                color="red"
                :link="true"
                :small="true"
                @click="deleteAlgo(algorithm)"
              >
                {{ $t('delete') }}
              </IdxBtn>
            </td>
          </tr>

          <tr v-if="showJson(algorithm.id)" :key="algorithm.id + '-2'">
            <td colspan="3">
              <!-- eslint-disable vuejs-accessibility/aria-props -->
              <BCollapse
                :open="showJson(algorithm.id)"
                :aria-id="`toggle-${algorithm.id}`"
              >
                <!-- eslint-enable vuejs-accessibility/aria-props -->
                <VAsync
                  v-slot="downloadPromise"
                  :await="downloadRequest(algorithm.id)"
                  @reject="$notify({ type: 'error', text: $t('error') })"
                >
                  <p v-if="downloadPromise.pending">
                    {{ $t('loading') }}
                  </p>
                  <pre v-else>{{ downloadPromise.results }}</pre>
                </VAsync>
              </BCollapse>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </VAsync>
</template>

<script>
import copyToClipboard from 'copy-to-clipboard';
import noteApi from '../../services/noteApi.js';
import download from '../../utils/download.js';
import constants from '@/utils/constants.js';

export default {
  props: {
    algorithm: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    error: null,
    versionsRequest: null,
    creationModal: false,
    shownJson: [],
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },
  mounted() {
    this.versionsRequest = noteApi.get(
      `napi/algorithm/${this.algorithm.id}/versions`
    );
  },
  methods: {
    async download(algorithmId) {
      var assayJson = await noteApi.get(
        `napi/algorithm/${algorithmId}/download`
      );
      download(
        assayJson,
        `${assayJson.name || assayJson.pipeline_name}_v${
          assayJson.version
        }.json`
      );
    },
    downloadRequest(algorithmId) {
      return noteApi.get(`napi/algorithm/${algorithmId}/download`);
    },
    deleteAlgo(software) {
      const deleteRequest = async () => {
        try {
          await noteApi.delete(`napi/algorithm/${software.id}`);

          this.$emit('onDelete', software.id);
          this.$notify({
            type: 'success',
            text: 'Deleted!',
          });
        } catch (error) {
          console.log(error);
          this.error = error;
          this.$notify({
            type: 'error',
            text: this.$t('error'),
          });
        }
      };
      this.versionsRequest = deleteRequest();
    },

    toggleShownJson(algorithmId) {
      const index = this.shownJson.indexOf(algorithmId);
      if (index >= 0) {
        this.shownJson.splice(index, 1);
      } else {
        this.shownJson.push(algorithmId);
      }
    },

    getPipelineGuiUrl(algorithId) {
      return `${constants.PIPELINE_GUI_BASE_URL}?assayID=${algorithId}`;
    },

    showJson(algorithmId) {
      return this.shownJson.includes(algorithmId);
    },

    async copyJson(algorithmId) {
      var assayJson = await noteApi.get(
        `napi/algorithm/${algorithmId}/download`
      );
      copyToClipboard(JSON.stringify(assayJson));
      this.$notify({
        type: 'success',
        text: 'Copied!',
      });
    },
  },
};
</script>
