<template>
  <div class="software-wrapper">
    <BLoading :is-full-page="false" :active="loading" />
    <div v-if="!loading" class="content-wrapper">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <RouterLink :to="`/software`">
              {{ $t('digital-assays') }}
            </RouterLink>
          </li>
          <li class="is-active">
            <a href="#" aria-current="page">{{ software.fullName }}</a>
          </li>
        </ul>
      </nav>

      <div class="box">
        <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
          <h2 class="mb-3">
            {{ $t('error') }}
          </h2>
          <p>{{ $t('unexpected-error-info-message') }}</p>
        </BMessage>

        <template v-else>
          <h1 class="p-4 text-center">
            {{ software.fullName }}
          </h1>

          <div class="description">
            <CytomineDescription
              :object="software"
              :can-edit="canManageSoftware"
            />
          </div>

          <hr />

          <div class="columns">
            <div class="column">
              <h2 class="mb-3">
                {{ $t('studies') }}
              </h2>
              <template v-if="projects.length">
                <span v-for="(project, index) in projects" :key="project.id">
                  <RouterLink :to="`/project/${project.id}`">{{
                    project.name
                  }}</RouterLink>
                  <span v-if="index < projects.length - 1">, </span>
                </span>
              </template>
              <em v-else-if="nbProjects > 0" class="has-text-grey">
                {{ $t('project-used-in-no-access') }}
              </em>
              <em v-else class="has-text-grey">
                {{ $t('project-not-used-in-any') }}
              </em>
            </div>
            <div class="column">
              <h2 class="mb-3">
                {{ $t('statistics') }}
              </h2>
              <div class="chart-container">
                <SoftwareStatisticsChart
                  :software="software"
                  css-classes="chart"
                />
              </div>
            </div>
          </div>

          <hr />

          <h2 class="mb-3">
            {{ $t('software-parameters') }}
          </h2>

          <BTable
            :data="softwareParameters"
            paginated
            :per-page="softwareParametersPerPage"
            default-sort="created"
            default-sort-direction="desc"
            pagination-size="is-small"
            detailed
            detail-key="id"
          >
            <BTableColumn :label="$t('name')" field="name" sortable>
              <template #default="props">
                {{ props.row.humanName }}
              </template>
            </BTableColumn>

            <BTableColumn :label="$t('type')" field="type" sortable>
              <template #default="props">
                {{ $t(props.row.type.toLowerCase()) }}
              </template>
            </BTableColumn>

            <BTableColumn :label="$t('default-value')" field="defaultValue">
              <template #default="props">
                {{ props.row.defaultParamValue }}
              </template>
            </BTableColumn>

            <BTableColumn :label="$t('mandatory')" field="mandatory">
              <template #default="props">
                <span v-if="props.row.required">
                  {{ $t('yes') }}
                </span>
                <span v-else>
                  {{ $t('no') }}
                </span>
              </template>
            </BTableColumn>

            <template #empty>
              <div class="content has-text-grey has-text-centered">
                <p>{{ $t('no-software-parameter') }}</p>
              </div>
            </template>

            <template #detail="{ row: parameter }">
              <SoftwareParameterDetails
                :key="parameter.id"
                :parameter="parameter"
              />
            </template>

            <template #bottom-left>
              <BSelect v-model="softwareParametersPerPage" size="is-small">
                <option value="10">
                  {{ $t('count-per-page', { count: 10 }) }}
                </option>
                <option value="25">
                  {{ $t('count-per-page', { count: 25 }) }}
                </option>
                <option value="50">
                  {{ $t('count-per-page', { count: 50 }) }}
                </option>
                <option value="100">
                  {{ $t('count-per-page', { count: 100 }) }}
                </option>
              </BSelect>
            </template>
          </btable>
        </template>
        </BTable>

        <hr />

        <h2 class="mb-3">
          {{ $t('other-information') }}
        </h2>

        <SoftwareDetails
          :software="software"
          :excluded-properties="['projects', 'name', 'description']"
        />
      </div>
    </div>
  </div>
</template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Software,
  SoftwareParameterCollection,
  ProjectCollection,
} from 'cytomine-client';

import CytomineDescription from '@/components/description/CytomineDescription.vue';
import SoftwareParameterDetails from '@/components/software/SoftwareParameterDetails.vue';
import SoftwareStatisticsChart from '@/components/charts/SoftwareStatisticsChart.js';
import SoftwareDetails from '@/components/software/SoftwareDetails.vue';

export default {
  name: 'SoftwareInformation',
  components: {
    SoftwareDetails,
    SoftwareStatisticsChart,
    SoftwareParameterDetails,
    CytomineDescription,
  },
  data() {
    return {
      loading: true,
      error: false,

      software: null,

      softwareParameters: [],
      softwareParametersPerPage: 10,

      projects: [],

      chartRevision: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    idSoftware() {
      return Number(this.$route.params.idSoftware);
    },
    canManageSoftware() {
      return this.currentUser.adminByNow;
    },
    nbProjects() {
      return this.projects.length;
    },
  },
  watch: {
    async idSoftware() {
      this.loading = true;
      await this.loadSoftware();
    },
    async software() {
      this.chartRevision++;
    },
  },
  async created() {
    await this.loadSoftware();
    this.loading = false;
  },
  methods: {
    async loadSoftware() {
      try {
        await Promise.all([
          this.fetchSoftware(),
          this.fetchSoftwareParameters(),
          this.fetchProjects(),
        ]);
      } catch (error) {
        console.log(error);
        this.error = true;
        return;
      }

      this.chartRevision++;
    },
    async fetchSoftware() {
      this.software = await Software.fetch(this.idSoftware);
    },
    async fetchSoftwareParameters() {
      this.softwareParameters = (
        await SoftwareParameterCollection.fetchAll({
          filterKey: 'software',
          filterValue: this.idSoftware,
        })
      ).array;
    },
    async fetchProjects() {
      this.projects = (
        await ProjectCollection.fetchAll({
          filterKey: 'software',
          filterValue: this.idSoftware,
        })
      ).array;
    },
  },
};
</script>

<style scoped>
h1 {
  text-transform: initial;
  letter-spacing: initial;
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 0.7rem;
}

li {
  margin-bottom: 0.5em;
}

.box {
  position: relative;
}

.chart-container {
  margin-top: 2em;
  height: 10em;
  position: relative;
}
</style>

<style lang="scss">
.software-wrapper {
  .table-wrapper {
    margin-bottom: 0;
  }

  .chart {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .field.is-horizontal {
    align-items: center;
  }

  .field-label {
    margin-right: 1em !important;
    padding-top: 0 !important;
  }
}

.table {
  background: none;
  position: relative;
  height: 3em;
}

td.prop-label {
  white-space: nowrap;
  font-weight: 600;
}

td.prop-content {
  width: 100%;
}

.description {
  font-size: 14px;
}
</style>
