<template>
  <VAsync :await="request">
    <template #default="promise">
      <div class="content-wrapper">
        <div class="panel">
          <div class="panel-heading flex justify-between align-center">
            <h1>{{ $t('digital-assays') }}</h1>

            <div>
              <a
                class="mr-3 button is-white"
                target="_blank"
                href="https://pipeline.ui.revealbio.com/"
              >
                {{ $t('assay-builder') }}
                <i
                  style="font-size:.8em;"
                  class="ml-2 mt-1 fas fa-external-link-alt"
                />
              </a>
              <IdxBtn
                v-if="!currentUser.guestByNow"
                color="blue"
                @click="creationModal = true"
              >
                {{ $t('upload') }}
              </IdxBtn>
            </div>
          </div>

          <BLoading
            v-if="promise.pending || isLoading"
            :is-full-page="false"
            active
          />

          <div class="p-3">
            <div class="flex">
              <BInput
                v-model="searchString"
                :placeholder="$t('search')"
                class="mr-4"
                type="search"
                icon="search"
                @input="debounceSearchString"
              />
            </div>

            <IdxTable
              :await="request"
              :data="algorithms"
              :opened-detailed="openedDetails"
              :current-page.sync="currentPage"
              :per-page.sync="perPage"
              :sort-by.sync="sortBy"
              :sort-direction.sync="sortDirection"
              :total="total"
              class="mt-4"
              detailed
              detail-key="id"
            >
              <template #default>
                <BTableColumn
                  v-slot="props"
                  :label="$t('name')"
                  field="name"
                  sortable
                >
                  {{ props.row.name }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('file-version')"
                  field="fileVersion"
                  sortable
                >
                  {{ props.row.fileVersion }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('status-last-update')"
                  field="created"
                  sortable
                >
                  {{ props.row.created | date('ll') }}
                </BTableColumn>
                <BTableColumn v-slot="props">
                  <IdxBtn
                    v-if="!currentUser.guestByNow"
                    :link="true"
                    :small="true"
                    @click="
                      () => {
                        updateModal = true;
                        updateAssayId = props.row.id;
                      }
                    "
                  >
                    <i class="fa fa-plus" />&nbsp;&nbsp;New Version
                  </IdxBtn>
                </BTableColumn>
              </template>

              <template #detail="{row: algorithm}">
                <AlgorithmDetails
                  :algorithm="algorithm"
                  @finishedUpdate="finishedUpdate"
                  @onDelete="onDelete"
                />
              </template>

              <template #empty>
                <div class="content has-text-grey has-text-centered">
                  <p>{{ $t('no-software') }}</p>
                </div>
              </template>
            </IdxTable>
          </div>
        </div>

        <!-- eslint-disable vuejs-accessibility/aria-props -->
        <BModal
          :active.sync="creationModal"
          :destroy-on-hide="false"
          trap-focus
          aria-role="dialog"
          aria-modal
          has-modal-card
          width="40rem"
        >
          <!-- eslint-enable vuejs-accessibility/aria-props -->
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                {{ $t('digital-assay-add') }}
              </p>
            </header>
            <div class="card-content">
              <VForm
                v-slot="form"
                class="content"
                @submit.prevent="onCreateAssay"
              >
                <IdxInput
                  v-model="createFileInput"
                  name="file"
                  type="file"
                  accept="application/json"
                  :label="$t('upload')"
                  class="mb-4"
                  required
                  @change="setFilename"
                />

                <IdxBtn
                  type="submit"
                  color="primary"
                  :disabled="!form.valid"
                  @click="creationModal = false"
                >
                  {{ $t('save') }}
                </IdxBtn>
              </VForm>
            </div>
          </div>
        </BModal>

        <!-- eslint-disable vuejs-accessibility/aria-props -->
        <BModal
          :active.sync="updateModal"
          :destroy-on-hide="false"
          trap-focus
          aria-role="dialog"
          aria-modal
          has-modal-card
          :width="640"
        >
          <!-- eslint-enable vuejs-accessibility/aria-props -->
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                {{ $t('digital-assay-update') }}
              </p>
            </header>
            <div class="card-content">
              <VForm
                v-slot="form"
                class="content"
                @submit.prevent="onUpdateAssay"
              >
                <IdxInput
                  id="file"
                  ref="file"
                  v-model="updateFileInput"
                  name="file"
                  type="file"
                  :label="$t('upload')"
                  class="mb-4"
                  required
                />
                <IdxBtn
                  type="submit"
                  color="primary"
                  :disabled="!form.valid"
                  @click="updateModal = false"
                >
                  {{ $t('save') }}
                </IdxBtn>
              </VForm>
            </div>
          </div>
        </BModal>
      </div>
    </template>
  </VAsync>
</template>

<script>
import debounce from 'lodash/debounce.js';
import noteApi from '../../services/noteApi.js';
import IdxTable, { DEFAULTS } from '../utils/IdxTable.vue';
import AlgorithmDetails from './AlgorithmDetails.vue';

export default {
  name: 'DigitalAssays',
  components: {
    IdxTable,
    AlgorithmDetails,
  },
  data: () => ({
    request: null,
    isLoading: false,
    error: null,
    creationModal: false,
    updateModal: false,
    allAlgorithms: [],

    searchString: '',
    sortBy: 'created',
    sortDirection: 'desc',
    perPage: DEFAULTS.perPage,
    currentPage: 1,
    total: 0,
    createFileInput: '',
    updateFileInput: '',
    listOfMatchedAlgorithmsWithVersion: [],

    openedDetails: [],
    updateAssayId: '',
  }),
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },

    /** @returns {Assay[]} */
    algorithms() {
      const { sortBy, sortDirection } = this;
      const algorithms = this.allAlgorithms;

      const sortDir = sortDirection === 'asc' ? 1 : -1;

      return algorithms.sort((a, b) => {
        if (a[sortBy] === b[sortBy]) return 0;

        const order = a[sortBy] > b[sortBy] ? 1 : -1;
        return order * sortDir;
      });
    },
  },

  watch: {
    currentPage: {
      handler() {
        this.fetchAlgorithms();
      },
      immediate: true,
    },
  },

  methods: {
    finishedUpdate() {
      this.openedDetails = [];
      this.fetchAlgorithms();
    },

    debounceSearchString: debounce(async function(value) {
      this.searchString = value;
      // @ts-ignore
      this.fetchAlgorithms();
    }, 500),

    fetchAlgorithmsRequest(queryParams) {
      return noteApi.get('napi/algorithms', {
        query: queryParams,
      });
    },

    fetchAlgorithms() {
      if (!this.currentUser.adminByNow) return;

      const queryParams = {
        page: this.currentPage - 1,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        name: this.searchString,
      };

      const fetchRequest = async () => {
        try {
          /** @type {{results: Array, total: number}} */
          // @ts-ignore
          const results = await this.fetchAlgorithmsRequest(queryParams);
          this.allAlgorithms = results.results;
          this.total = results.total;

          if ((this.currentPage - 1) * this.perPage >= this.total) {
            this.currentPage = 1;
          }
        } catch (error) {
          console.log(error);
          this.error = error;
        }
      };
      this.request = fetchRequest();
    },

    setFilename(event) {
      const [file] = event.target.files;

      /** @type {HTMLInputElement} */
      const inputEl = this.$el.querySelector('input[name=name]');
      // removes file extension
      inputEl.value = file.name.replace(/\.[^/.]+$/, '');
      // dispatch event for input of form to report validity states
      inputEl.dispatchEvent(new Event('input', { bubbles: true }));
    },

    /// Returns 'true' if valid JSON file, otherwise, returns false
    async validateFile(data) {
      return new Promise((resolve, reject) => {
        const onReaderLoad = (event) => {
          try {
            var obj = JSON.parse(event.target.result);
            resolve({ isValid: true, reason: null });
          } catch (ex) {
            resolve({ isValid: false, reason: this.$t('invalid-json') });
          }
        };

        var reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.onerror = () => {
          resolve({
            isValid: false,
            reason: this.$t('invalid-permissions'),
          });
        };
        reader.onabort = () => {
          resolve({ isValid: false, reason: this.$t('unknown') });
        };
        reader.readAsText(data.get('file'));
      });
    },

    async onCreateAssay(event) {
      const form = event.target;
      const data = new FormData(form);
      const { isValid, reason } = await this.validateFile(data);
      if (!isValid) {
        this.clearForm();
        this.$notify({
          type: 'error',
          text: `${this.$t('error')}: ${reason}`,
        });
        return;
      }

      this.isLoading = true;
      try {
        await noteApi.post('napi/algorithm', {
          body: data,
        });
        this.fetchAlgorithms();
        this.$notify({
          type: 'success',
          text: this.$t('created'),
        });
      } catch (error) {
        console.log(error);
        this.error = error;
        this.$notify({
          type: 'error',
          text: `${this.$t('error')}: ${(error && error.message) || ''}`,
        });
      } finally {
        this.clearForm();
        this.isLoading = false;
      }
    },

    async onUpdateAssay(event) {
      const form = event.target;
      const data = new FormData(form);
      const { isValid, reason } = await this.validateFile(data);
      if (!isValid) {
        this.clearForm();
        this.$notify({
          type: 'error',
          text: `${this.$t('error')}: ${reason}`,
        });
        return;
      }

      this.isLoading = true;
      try {
        await noteApi.patch(`napi/algorithm/${this.updateAssayId}`, {
          body: data,
        });
        this.fetchAlgorithms();
        this.$notify({
          type: 'success',
          text: this.$t('updated'),
        });

        this.$emit('finishedUpdate');
      } catch (error) {
        console.log(error);
        this.error = error;

        this.$notify({
          type: 'error',
          text: `${this.$t('error')}: ${(error && error.message) || ''}`,
        });
      } finally {
        this.clearForm();
        this.isLoading = false;
      }
    },

    clearForm() {
      this.createFileInput = '';
      this.updateFileInput = '';
    },

    onDelete(softwareId) {
      this.isLoading = true;
      try {
        this.fetchAlgorithms();
      } catch (error) {
        this.error = error;
        this.$notify({
          type: 'error',
          text: `${this.$t('error')}: ${(error && error.message) || ''}`,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
