<template>
  <span v-if="value" class="tag is-success">
    <i class="fas fa-check" />&nbsp;{{ $t('yes') }}
  </span>
  <span v-else class="tag is-danger">
    <i class="fas fa-times" />&nbsp;{{ $t('no') }}
  </span>
</template>

<script>
export default {
  name: 'BooleanItem',
  props: {
    value: Boolean,
  },
};
</script>

<style scoped></style>
